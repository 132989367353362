@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '../variables.scss';
.event-calendar {
  position: relative;
  width: 100%;
  overflow-x: auto;
  .fc {
    &.fc-ltr {
      min-width: 800px;
    }
  }
  .fc-button-primary {
    background: $white-color;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    color: $text-dark-color;
    border: 0;
    border-radius: 2px;
    font-size: $font-small;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    text-transform: capitalize;
    &:hover, &:not(:disabled):active:focus {
      background: $white-color;
      border: 0;
      color: $text-dark-color;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    }
    &.fc-button-active {
      background: $white-color;
      border: 0;
      color: $secondary-color;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
      &:focus {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
      }
    }
  }
  .fc-center {
    position: absolute;
    left: 175px;
    top: 0;
    width: 150px;
    text-align: center;
    h2 {
      background: $white-color;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
      color: $text-dark-color;
      border: 0;
      border-radius: 0;
      font-size: $font-small;
      height: 36px;
      line-height: 36px;
      padding: 0;
      text-transform: capitalize;
    }
  }
  .fc-button-group {
    .fc-prev-button {
      width: 36px;
      padding: 0;
      .fc-icon {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: $font-small;
      }
    }
    .fc-next-button {
      width: 36px;
      padding: 0;
      margin-left: 152px;
      .fc-icon {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: $font-small;
      }
    }
  }
  .fc-today-button {
    width: 100px;
    margin: 0 38px 0 0;
  }
  .fc-toolbar {
    .fc-left {
      display: inline-flex;
      .fc-button-group {
        order: 2;
      }
      .fc-today-button {
        order: 1;
      }
    }
    .fc-right {
      .fc-button-group {
        button {
          min-width: 70px;
          margin-left: 1px;
        }
      }
    }
  }
  th.fc-day-header {
    height: 48px;
    line-height: 48px;
    font-weight: normal;
    font-size: 14px;
  }
  .fc-event, .fc-event-dot {
    background: $green-dark-color;
    color: $white-color;
    border: 0;
    .fc-content {
      color: $white-color;
      height: 24px;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
    }
    .fc-time {
      font-weight: normal;
    }
  }
  .fc-container, .fc-view-container{
    padding: 15px;
    background: $white-color;
    box-shadow: 4px 8px 24px 0 rgba(0, 0, 0, 0.1);
  }
  .fc-unthemed {
    td.fc-today {
      background: $light-green-color;
    }
  }
}
.in-scroll {
  height: 400px;
  overflow-y: auto;
}
.infinite-scroll-component  {
  overflow: hidden;
  height: 400px;
}
.fc-time{
   display : none;
}
