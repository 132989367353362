/*=== colors ===*/
$primary-color: #00cc99;
$primary-gradient: #00cc99;
$secondary-color: #39bfe2;
$tertiary-color: #00cc99;
$text-dark-color: #2e3543;
$text-light-color: #596377;
$text-sweet-light: #b9b9b9;
$gray-light-color: #f8f8f8;
$gray-sweet-light: #f3f2f2;
$gray-dark-color: #e6e6e6;
$gray-border-color: #979797;
$error-text-color: #ff3030;
$text-coral-color: #f74a4a;
$dispute-row-color: #FFE0E0;
$light-green-color: #dcfaea;
$light-blue-color: #f1f9ff;
$off-white-color: #f3faf2;
$off-red-color: #fff5f4;
$ice-light-color: #edfcf5;
$green-border-color: #4ce59e;
$green-text-color: #4be59e;
$green-dark-color: #0fd178;
$tag-blue-bg: #d7edff;
$white-color: #ffffff;
$black-color: #000000;
$key-color: #f8fefb;
$card-background-color: #fafafa;
$usertype-bg: #f8f9f9;
$chat-send-color: #cbf7df;
$green-fade: #baf5d4;
$graph-border: #e1eaff;
$log-btn-bg: #99d6ff;
$flag-btn-bg: #fcc0b7;
$table-green-bg: #f1fdf7;
$slider-bg: #1c1f28;
$fade-green-bg: #f6fdf9;
$sky-blue-light: #e8f3fb;
$sky-blue-dark : #1c90dc;
$blue-fade: #dff0f6;
$card-active-color: #d9fae6;
/*====Font family===*/
$Roboto-light: 'Roboto-light', sans-serif;
$Roboto-regular: 'Roboto-regular', sans-serif;
$Roboto-medium: 'Roboto', sans-serif;
$Roboto-bold: 'Roboto', sans-serif;
$Oswald-regular: 'Oswald-regular', sans-serif;
$Oswald-bold: 'Oswald-bold', sans-serif;
$font-bold: 'Roboto-bold', sans-serif;
$font-roboto-medium: 'Roboto-medium', sans-serif;
/*=== font size ===*/
$font-small: 14px;
$font-regular: 16px;
$font-medium: 18px;
$font-large: 20px;
$font-extra-large: 22px;

// Screen size variables
$screen-xs-min: 425px;  // Tiny phones
$screen-sm-min: 767px;  // Small tablets and large smartphones (landscape view)
$screen-md-min: 768px;  // Small tablets (portrait view)
$screen-lg-min: 992px;  // Tablets and small desktops
$screen-lg-max: 1024px; // Tablets large device
$screen-xl-min: 1200px; // Large tablets and desktops
$screen-xl-max: 1280px; // Large desktops


